<template>
  <modal name="update-alarm" class="modal-inner modal-update-alarm" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="resetForm">
    <span class="close-button" @click="hide('update-alarm')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      <span v-if="updateInfo">{{ updateInfo.unit_name }}</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formUpdateAlarm" v-if="updateInfo != null">
        <div class="flex flex-wrap border-b border-secondary-500 mx-2 pb-4 mb-5">
          <div class="w-full md:w-1/2">
            <div class="flex flex-wrap" v-if="!/(SRT334)|(TWIG Beacon)|(4G Box)|(Anchor Beacon)|(AJAX)/.test(updateInfo.type) && updateInfo.gps_active && updateInfo.lastSeen">
              <div class="small">
                <span v-if="new RegExp('^SRT').test(updateInfo.type)" class="last-seen mr-2 text-sm" v-bind:class="{ inactive: updateInfo.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                <span v-if="new RegExp('^TWIG').test(updateInfo.type)" class="last-seen mr-2 text-sm" v-bind:class="{ inactive: updateInfo.lastSeenTime > 1000 * 60 * 60 * 2.5 }">⬤</span>
                <span v-if="new RegExp('^EMERIT').test(updateInfo.type)" class="last-seen mr-2 text-sm" v-bind:class="{ inactive: updateInfo.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                <span v-if="new RegExp('^TELTONIKA').test(updateInfo.type)" class="last-seen mr-2 text-sm" v-bind:class="{ inactive: updateInfo.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                <span class="text-xs">{{ updateInfo.lastSeen }}</span>
              </div>
              <router-link v-if="!/(4G Box)/.test(updateInfo.type)" class="btn-green-outline ml-4" :to="'/gps-unit/customer/' + updateInfo.parent_id + '/-/' + updateInfo._id" target="_blank">
                Track
                <BaseIcon icon="map-marker-alt" />
              </router-link>
            </div>
            <div class="flex flex-wrap" v-if="/(SRT334)|(TWIG Beacon)|(4G Box)|(Anchor Beacon)|(AJAX)/.test(updateInfo.type) && updateInfo.lastSeen">
              <div class="small">
                <span class="last-seen mr-2 text-sm" v-bind:class="{ inactive: updateInfo.tagLastSeen > 7 }">⬤</span>
                <span class="text-xs">{{ updateInfo.lastSeen }}</span>
              </div>
              <router-link v-if="!/(4G Box)/.test(updateInfo.type)" class="btn-green-outline ml-4" :to="'/gps-unit/customer/' + updateInfo.parent_id + '/-/' + updateInfo._id" target="_blank">
                Track
                <BaseIcon icon="map-marker-alt" />
              </router-link>
            </div>
          </div>
          <div class="w-full md:w-1/2 clearfix">
            <button class="btn-blue-outline float-right" @click.prevent="$modal.show('update-alarm-contract')">
              {{ $t("alarm_view.show_action_documents") }}
              <BaseIcon icon="arrow-right" />
            </button>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.unit_name" type="text" :field_name="$t('alarm_view.name_of_product')" rules="required" />
          </div>
          <!-- start move alarm -->
          <div class="w-full md:w-1/2 px-2" v-if="user.role == 'admin' || user.role == 'reseller' || user.role == 'superuser'">
            <BaseSelect
              v-model="customer_id"
              :options="customers"
              optionSelector="_id"
              nameSelector="name"
              field_name="Kund"
              rules="required"
              @change="
                filterUnitByCustomer();
                updateInfo.parent_id = '';
              "
            />
          </div>
          <div class="w-full md:w-1/2 px-2" v-if="user.role == 'admin' || user.role == 'reseller' || user.role == 'superuser'">
            <ValidationProvider name="Verksamhet" rules="required" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-700 text-sm font-bold pb-2 font-serif">{{ $t("alarm_view.department") }}<b class="text-red-500 ml-1">*</b></label>
                <select v-model="updateInfo.parent_id" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 hover:border-gray-400">
                  <option value="">{{ $t("alarm_view.select_business") }}</option>
                  <option :value="o['unit_id']" v-for="(o, i) in customer_units" :key="i">{{ o["name"] }}</option>
                </select>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <!-- end move alarm admin/reseller, v-else below line -->
          <!-- customer/seller can move alarm within unit -->
          <div class="w-full md:w-1/2 px-2" v-else>
            <ValidationProvider name="Verksamhet" rules="required" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-700 text-sm font-bold pb-2 font-serif">{{ $t("alarm_view.department") }}<b class="text-red-500 ml-1">*</b></label>
                <select v-model="updateInfo.parent_id" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 hover:border-gray-400" :readonly="user.role == 'user'" :disabled="user.role == 'user'">
                  <option value="">{{ $t("alarm_view.select_business") }}</option>
                  <option :value="o['unit_id']" v-for="(o, i) in units" :key="i">{{ o["name"] }}</option>
                </select>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.type" type="text" field_name="Typ av enhet" readonly disabled />
          </div>

          <!-- Camera related-->

          <div v-if="new RegExp('(Avigilon Alta Compact Dome 5MP)').test(updateInfo.type)" class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.ava_flex.tag_serial_number" type="text" field_name="Tag serial" />
          </div>
          <div v-if="new RegExp('(Avigilon Alta Compact Dome 5MP)').test(updateInfo.type)" class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.ava_flex.webhook" type="text" field_name="Webbhook" />
          </div>
          <div v-if="new RegExp('(Avigilon Alta Compact Dome 5MP)').test(updateInfo.type)" class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.ava_flex.camera_token" type="text" field_name="Token" />
          </div>

          <!-- End camera related-->

          <div v-if="!new RegExp('(SRT334)|(TWIG Beacon)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(updateInfo.type)" class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.phone" type="text" :field_name="$t('alarm_view.phone')" readonly disabled />
          </div>
          <div v-if="!new RegExp('(Avigilon Alta Compact Dome 5MP)').test(updateInfo.type)" class="w-full md:w-1/2 px-2">
            <BaseInput v-model="updateInfo.modelnumber" type="text" :field_name="$t('alarm_view.serial_number')" readonly disabled />
          </div>

          <div class="w-full md:w-1/2 px-2 mt-4" v-if="user.role == 'admin' || user.role == 'seller'">
            <div class="flex">
              <div v-if="!new RegExp('(SRT334)|(SRT330)|(TWIG Beacon)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(updateInfo.type)" class="w-full md:w-1/3">
                <BaseSwitch v-model="updateInfo.gps_active" field_name="SecurTrack" />
              </div>
              <div v-if="!new RegExp('(SRT334)|(SRT330)|(TWIG Beacon)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(updateInfo.type)" class="w-full md:w-1/3">
                <BaseSwitch v-model="updateInfo.gps_log_active" field_name="SecurTool" />
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-2 mt-4" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(updateInfo.type)">
            <BaseSelect v-model="updateInfo.link_validity" :options="validityTime" :field_name="$t('alarm_view.link_validity')" nameSelector="name" optionSelector="time" rules="required" />
          </div>

          <div class="w-full mx-2 border-b border-secondary-400 mt-5 mb-4" v-if="(user.role == 'admin' || user.role == 'seller' || (user.role == 'reseller' && user.reseller_special_access.indexOf('sc_device_setting') > -1)) && !new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(updateInfo.type)"></div>

          <div class="w-full flex flex-wrap" v-if="(user.role == 'admin' || user.role == 'seller' || (user.role == 'reseller' && user.reseller_special_access.indexOf('sc_device_setting') > -1)) && !new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(updateInfo.type)">
            <div class="w-full px-2 text-sm cursor-pointer" @click="isConfigVisible = !isConfigVisible">
              <div class="clear-both">
                <div class="float-left text-gray-600 font-bold pb-2 font-serif">{{ $t("alarm_view.additional_services") }}</div>
                <div class="float-right">
                  <BaseIcon v-if="!isConfigVisible" class="" icon="chevron-down" />
                  <BaseIcon v-if="isConfigVisible" class="" icon="chevron-up" />
                </div>
              </div>
            </div>
          </div>

          <div class="w-full flex flex-wrap" v-if="(user.role == 'admin' || user.role == 'seller' || (user.role == 'reseller' && user.reseller_special_access.indexOf('sc_device_setting') > -1)) && isConfigVisible && !new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(updateInfo.type)">
            <div class="w-full md:w-1/2 px-2 mt-5" v-if="!/(^EMERIT)/.test(updateInfo.type)">
              <div class="w-full flex flex-col">
                <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">
                  {{ getInternalDevicesAlertType() }}
                  <span v-if="new RegExp('^SRT').test(updateInfo.type)">(SRT)</span>
                  <span v-if="new RegExp('^TWIG').test(updateInfo.type)">(TWIG)</span>
                  <span v-if="new RegExp('^4G Box').test(updateInfo.type)">(4G Box)</span>
                  <span v-if="new RegExp('^AJAX').test(updateInfo.type)">(AJAX)</span>
                </div>
                <div class="w-full">
                  <treeselect ref="interdeviceTreeSelect" v-model="interdeviceAlertSelectNodes" :options="interdeviceAlerts" :alwaysOpen="true" :appendToBody="false" :defaultExpandLevel="2" :disableBranchNodes="false" :showCount="true" showCountOf="LEAF_DESCENDANTS" openDirection="below" :searchable="true" :maxHeight="500" :limit="2" :clearable="false" :multiple="true" value-consists-of="LEAF_PRIORITY" valueFormat="object" noChildrenText="No Devices" class="treeselect-inline">
                    <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="[labelClassName, node.isNew ? 'bg-yellow-50' : '']" class="text-sm">
                      {{ node.label }}
                      <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                    </label>
                  </treeselect>
                </div>

                <div class="w-full mt-4" v-if="new RegExp('(^TWIG)|(^SRT40)').test(updateInfo.type) && interdeviceAlertSelectNodes.length > 0">
                  <BaseTextarea rows="1" v-model="updateInfo.vibration_pushb_template" maxlength="130" field_name="On-Screen Message Push-Button" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_pushb_template += 'Skarpt <LarmNamn> <RF-Tagg>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_pushb_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_pushb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.vibration_pushb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <div class="w-full mt-4" v-if="new RegExp('^TWIG|(^SRT40)').test(updateInfo.type) && interdeviceAlertSelectNodes.length > 0">
                  <BaseTextarea rows="1" v-model="updateInfo.vibration_mandown_template" maxlength="130" field_name="On-Screen Message Man-Down" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_mandown_template += 'Man-Down <LarmNamn> <RF-Tagg>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_mandown_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_mandown_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.vibration_mandown_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <div class="w-full mt-4" v-if="new RegExp('^TWIG|(^SRT40)').test(updateInfo.type) && interdeviceAlertSelectNodes.length > 0">
                  <BaseTextarea rows="1" v-model="updateInfo.vibration_callb_template" maxlength="130" field_name="On-Screen Message Call-Button" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_callb_template += 'Internt <LarmNamn> <RF-Tagg>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_callb_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_callb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.vibration_callb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <div class="w-full mt-4" v-if="new RegExp('(^SRT40)').test(updateInfo.type) && interdeviceAlertSelectNodes.length > 0">
                  <BaseTextarea rows="1" v-model="updateInfo.vibration_callb2_template" maxlength="130" field_name="On-Screen Message Call-Button 2" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_callb2_template += 'Internt <LarmNamn> <RF-Tagg>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_callb2_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.vibration_callb2_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.vibration_callb2_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="w-full md:w-1/2 px-2 mt-5 py-4 md:py-0 border-t md:border-t-0">
              <BaseSelect v-model="updateInfo.sms_alert" :options="sms_alerts" field_name="SMS" nameSelector="name" optionSelector="id" />
              <div class="w-full mt-3 flex flex-col" v-if="updateInfo.sms_alert != ''">
                <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">Nummer <b class="text-xs italic font-normal font-sans text-secondary-500">(Fullständigt mobilnummer med land/riktnummer)</b></div>
                <div class="w-full flex flex-wrap mt-2" v-for="(n, i) in updateInfo.sms_alert_phone" :key="i">
                  <div class="w-11/12">
                    <ValidationProvider :name="'SMS Nummer ' + (i + 1)" rules="required" v-slot="{ classes, errors }">
                      <div class="input-validate" :class="classes">
                        <input v-model="n.msisdn" type="text" @keypress="isNumber($event)" maxlength="15" class="w-full bg-gray-100 rounded text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 py-2" />
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <button class="w-1/12 text-red-500 pl-2" @click.prevent="removeSMSInput(i)"><BaseIcon icon="minus-circle" /></button>
                </div>
                <div class="w-full">
                  <button class="btn-blue-outline mt-2" @click.prevent="addSMSInput()">Tillägga <BaseIcon icon="plus" class="ml-1" /></button>
                </div>
                <div class="w-full mt-4" v-if="updateInfo.sms_alert == 'sms_pushb' || updateInfo.sms_alert == 'sms_both'">
                  <BaseTextarea rows="2" v-model="updateInfo.sms_pushb_template" maxlength="160" field_name="SMS Push-Button" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_pushb_template += 'Skarpt <LarmNamn> <RF-Tagg> <SecurCloud>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_pushb_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_pushb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_pushb_template += '<SecurCloud>'">SecurCloud<BaseIcon icon="map-marker-alt" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.sms_pushb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <div class="w-full mt-4" v-if="updateInfo.sms_alert == 'sms_mandown' || updateInfo.sms_alert == 'sms_both'">
                  <BaseTextarea rows="2" v-model="updateInfo.sms_mandown_template" maxlength="160" field_name="SMS Man-Down" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_mandown_template += 'Man-Down <LarmNamn> <RF-Tagg> <SecurCloud>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_mandown_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_mandown_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_mandown_template += '<SecurCloud>'">SecurCloud<BaseIcon icon="map-marker-alt" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.sms_mandown_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <div class="w-full mt-4" v-if="updateInfo.sms_alert == 'sms_callb' || updateInfo.sms_alert == 'sms_both'">
                  <BaseTextarea rows="2" v-model="updateInfo.sms_callb_template" maxlength="160" field_name="SMS Call-Button" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_callb_template += 'Internt <LarmNamn> <RF-Tagg> <SecurCloud>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_callb_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_callb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_callb_template += '<SecurCloud>'">SecurCloud<BaseIcon icon="map-marker-alt" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.sms_callb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <div class="w-full mt-4" v-if="updateInfo.sms_alert == 'sms_callb2' || updateInfo.sms_alert == 'sms_both'">
                  <BaseTextarea rows="2" v-model="updateInfo.sms_callb2_template" maxlength="160" field_name="SMS Call-Button 2" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_callb2_template += 'Internt <LarmNamn> <RF-Tagg> <SecurCloud>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_callb2_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_callb2_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_callb2_template += '<SecurCloud>'">SecurCloud<BaseIcon icon="map-marker-alt" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.sms_callb2_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <!-- Five push button -->
                <div class="w-full mt-4" v-if="updateInfo.sms_alert == 'sms_five_push' || updateInfo.sms_alert == 'sms_both'">
                  <BaseTextarea rows="2" v-model="updateInfo.sms_five_push_template" maxlength="160" field_name="SMS 5 button push" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_five_push_template += 'Test <LarmNamn> <RF-Tagg> <SecurCloud>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_five_push_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_five_push_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_five_push_template += '<SecurCloud>'">SecurCloud<BaseIcon icon="map-marker-alt" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.sms_five_push_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <!-- Timer alarm -->
                <div class="w-full mt-4" v-if="updateInfo.sms_alert == 'sms_timer_alarm' || updateInfo.sms_alert == 'sms_both'">
                  <BaseTextarea rows="2" v-model="updateInfo.sms_timer_alarm_template" maxlength="160" field_name="SMS Timer Alarm" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_timer_alarm_template += 'Timer Alarm <LarmNamn> <RF-Tagg> <SecurCloud>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_timer_alarm_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_timer_alarm_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.sms_timer_alarm_template += '<SecurCloud>'">SecurCloud<BaseIcon icon="map-marker-alt" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.sms_timer_alarm_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!/(4G Box)|(AJAX)/.test(updateInfo.type)" class="w-full md:w-1/2 px-2 mt-5 py-4 border-t">
              <BaseSelect v-model="updateInfo.display_alert" :options="display_alerts" field_name="Display SRT 330/430" nameSelector="name" optionSelector="id" />
              <div class="w-full mt-3 flex flex-col" v-if="updateInfo.display_alert != ''">
                <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">Nummer <b class="text-xs italic font-normal font-sans text-secondary-500">(Fullständigt mobilnummer med land/riktnummer)</b></div>
                <div class="w-full flex flex-wrap mt-2" v-for="(n, i) in updateInfo.display_alert_phone" :key="i">
                  <div class="w-11/12">
                    <ValidationProvider :name="'Display Nummer ' + (i + 1)" rules="required" v-slot="{ classes, errors }">
                      <div class="input-validate" :class="classes">
                        <input v-model="n.msisdn" type="text" @keypress="isNumber($event)" maxlength="15" class="w-full bg-gray-100 rounded text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 py-2" />
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </div>
                  <button class="w-1/12 text-red-500 pl-2" @click.prevent="removeDisplayInput(i)"><BaseIcon icon="minus-circle" /></button>
                </div>
                <div class="w-full">
                  <button class="btn-blue-outline mt-2" @click.prevent="addDisplayInput()">Tillägga <BaseIcon icon="plus" class="ml-1" /></button>
                </div>
                <div class="w-full mt-4" v-if="updateInfo.display_alert == 'display_pushb' || updateInfo.display_alert == 'display_both'">
                  <BaseTextarea rows="2" v-model="updateInfo.display_pushb_template" maxlength="160" field_name="Display Push-Button" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_pushb_template += 'Skarpt <LarmNamn> <RF-Tagg>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_pushb_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_pushb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.display_pushb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <div class="w-full mt-4" v-if="updateInfo.display_alert == 'display_mandown' || updateInfo.display_alert == 'display_both'">
                  <BaseTextarea rows="2" v-model="updateInfo.display_mandown_template" maxlength="160" field_name="Display Man-Down" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_mandown_template += 'Man-Down <LarmNamn> <RF-Tagg>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_mandown_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_mandown_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.display_mandown_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <div class="w-full mt-4" v-if="updateInfo.display_alert == 'display_callb' || updateInfo.display_alert == 'display_both'">
                  <BaseTextarea rows="2" v-model="updateInfo.display_callb_template" maxlength="160" field_name="Display Call-Button" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_callb_template += 'Internt <LarmNamn> <RF-Tagg>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_callb_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_callb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.display_callb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <div class="w-full mt-4" v-if="updateInfo.display_alert == 'display_callb2' || updateInfo.display_alert == 'display_both'">
                  <BaseTextarea rows="2" v-model="updateInfo.display_callb2_template" maxlength="160" field_name="Display Call-Button 2" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_callb2_template += 'Internt <LarmNamn> <RF-Tagg>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_callb2_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_callb2_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.display_callb2_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
                <div class="w-full mt-4" v-if="updateInfo.display_alert == 'display_timer' || updateInfo.display_alert == 'display_both'">
                  <BaseTextarea rows="2" v-model="updateInfo.display_timer_template" maxlength="160" field_name="Display Timer alarm" rules="required" />
                  <div class="">
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_timer_template += 'Timer Alarm <LarmNamn> <RF-Tagg>'">Default</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_timer_template += '<LarmNamn>'">Larmnamn</button>
                    <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="updateInfo.display_timer_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                    <button class="btn-red-outline text-xxs mt-1" @click.prevent="updateInfo.display_timer_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full px-4 py-4 mt-5 bg-gray-50" v-if="!/(4G Box)|(AJAX)/.test(updateInfo.type)">
              <label class="inline-flex items-center"> <input v-model="updateInfo.send_to_alarm_center" type="checkbox" class="form-checkbox h-4 w-4" /><span class="ml-2 text-gray-600 text-sm font-serif font-bold">Skicka larm till larmcentral</span> </label>
              <template v-if="updateInfo.send_to_alarm_center">
                <div class="w-full" v-for="r in reportTypes" :key="r.id">
                  <label class="inline-flex items-center mt-3">
                    <input v-model="updateInfo.tunnel_settings" :value="r.id" type="checkbox" class="form-checkbox h-3 w-3" /><span class="ml-2 text-sm font-sans font-semibold">{{ r.name }}</span>
                  </label>
                </div>
              </template>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <router-link tag="button" v-if="(user.role == 'admin' || user.role == 'seller') && updateInfo" class="btn-blue-outline px-3 py-2 float-left" :to="'/admin/skapa-larm/' + customerId + '/' + updateInfo.parent_id + '/' + updateInfo._id">
          Kopiera
          <BaseIcon icon="clipboard" class="ml-1" />
        </router-link>
        <button v-else class="btn-red-outline px-3 py-2 float-left" @click="hide('update-alarm')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="updateAlarm()">{{ $t("user_actions.save") }}<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const MODAL_WIDTH = 800;

export default {
  components: {
    Treeselect,
  },

  props: {
    alarmInfo: {
      required: true,
      default: null,
    },
    units: {
      required: true,
      default: null,
    },
    customerId: {
      default: "",
    },
    id: {
      default: "-",
    },
    unitAlarms: {
      required: true,
    },
    devicesTree: {
      default: [],
    },
  },

  watch: {
    alarmInfo(newVal) {
      // eslint-disable-line
      this.updateInfo = _.cloneDeep(newVal);
      this.updateInfo.ava_flex = this.updateInfo.ava_flex || {};
      // start move alarm
      if (this.user.role == "admin" || this.user.role == "reseller" || this.user.role == "superuser") {
        this.setCustomerId();
        this.filterUnitByCustomer();
      }
      // end move alarm
      this.isConfigVisible = false;
      this.populateInterDeviceAlertTree();
    },
    devicesTree(newVal) {
      // eslint-disable-line
      this.unitDevicesTree = _.cloneDeep(newVal);
      this.populateInterDeviceAlertTree();
    },
    customers() {
      // eslint-disable-line
      this.setCustomerId();
      if (this.user.role == "admin" || this.user.role == "reseller" || this.user.role == "superuser") this.filterUnitByCustomer();
    },
    all_units() {
      // eslint-disable-line
      this.filterUnitByCustomer();
    },
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  data() {
    return {
      updateInfo: null,
      unitDevicesTree: [],
      // start move alarm
      customer_id: "",
      customers: [],
      all_units: [],
      customer_units: [],
      // end move alarm
      validityTime: [
        { name: "30 minuter", time: 1800 },
        { name: "1 timme", time: 3600 },
        { name: "3 timmar", time: 10800 },
        { name: "6 timmar", time: 21600 },
        { name: "12 timmar", time: 43200 },
      ],
      interdeviceAlertsDefault: [
        { level: "event", id: "all", label: "All (Default)", isDisabled: false, isDefaultExpanded: false, children: null, message: "<Event> <DeviceName> <RF-Tagg>" },
        { level: "event", id: "push_button", label: "Push Button", isDisabled: false, isDefaultExpanded: false, children: null, message: "Skarpt <DeviceName> <RF-Tagg>" },
        { level: "event", id: "call_b", label: "Call Button", isDisabled: false, isDefaultExpanded: false, children: null, message: "Internt <DeviceName> <RF-Tagg>" },
        { level: "event", id: "call_b2", label: "Call Button 2", isDisabled: false, isDefaultExpanded: false, children: null, message: "Internt 2 <DeviceName> <RF-Tagg>" },
        { level: "event", id: "man_down", label: "Man Down", isDisabled: false, isDefaultExpanded: false, children: null, message: "Man-Down <DeviceName> <RF-Tagg>" },
        { level: "event", id: "4gbox", label: "4G Box", isDisabled: false, isDefaultExpanded: false, children: null, message: "" },
        { level: "event", id: "five_push", label: "5 button push", isDisabled: false, isDefaultExpanded: false, children: null, message: "Provlarm <DeviceName> <RF-Tagg>" },
        { level: "event", id: "ajax_critical", label: "AJAX SOS", isDisabled: false, isDefaultExpanded: false, children: null, message: "" },
        { level: "event", id: "timer_alarm", label: "Timer alarm", isDisabled: false, isDefaultExpanded: false, children: null, message: "Timer Alarm <DeviceName> <RF-Tagg>" },
      ],
      interdeviceAlerts: [],
      interdeviceAlertSelectNodes: [],
      sms_alerts: [
        { id: "sms_pushb", name: "Push B" },
        { id: "sms_mandown", name: "Man Down" },
        { id: "sms_callb", name: "Call B" },
        { id: "sms_callb2", name: "Call B 2" },
        { id: "sms_4gbox", name: "4G Box" },
        { id: "sms_five_push", name: "5 button push (provlarm)" },
        { id: "sms_ajax_critical", name: "AJAX SOS" },
        { id: "sms_timer_alarm", name: "Timer alarm" },
        { id: "sms_both", name: "Alla" },
      ],
      display_alerts: [
        { id: "display_pushb", name: "Push B" },
        { id: "display_mandown", name: "Man Down" },
        { id: "display_callb", name: "Call B" },
        { id: "display_callb2", name: "Call B 2" },
        { id: "display_4gbox", name: "4G Box" },
        { id: "display_ajax_critical", name: "AJAX SOS" },
        { id: "display_timer", name: "Timer alarm" },
        { id: "display_both", name: "Alla" },
      ],
      reportTypes: [{ name: "Internlarm", id: "call_b" }],
      isConfigVisible: false,
    };
  },
  methods: {
    // start move alarm
    getCustomers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/customer/`)
        .then((response) => {
          this.customers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getUnits() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/units/`)
        .then((response) => {
          this.all_units = response.data.data;
          this.customer_units = _.filter(this.all_units, { parent_id: this.customer_id });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getResellerCustomers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/reseller/${this.id}/customers`)
        .then((response) => {
          this.customers = response.data.customers;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getResellerUnits() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/reseller/${this.id}/units`)
        .then((response) => {
          this.all_units = response.data.units;
          this.customer_units = _.filter(this.all_units, { parent_id: this.customer_id });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    setCustomerId() {
      let customer = _.find(this.customers, { customer_id: this.customerId });
      if (customer) this.customer_id = customer._id;
    },

    filterUnitByCustomer() {
      this.customer_units = _.filter(this.all_units, { parent_id: this.customer_id });
    },

    getUnit(unit_id) {
      let units = this.customer_units;
      if (this.user.role != "admin" && this.user.role != "reseller") units = this.units;

      let unit = _.find(units, { unit_id: unit_id });
      if (unit) return unit;
      else return null;
    },
    // end move alarm

    updateAlarm() {
      this.$refs.formUpdateAlarm.validate().then((success) => {
        if (!success) {
          this.handleError(null, "Några av obligatoriska fält är tomma");
          return;
        }

        let smsPhone = this.updateInfo.sms_alert_phone ? this.filterPhoneNumber(this.updateInfo.sms_alert_phone) : [];
        let displayPhone = this.updateInfo.display_alert_phone ? this.filterPhoneNumber(this.updateInfo.display_alert_phone) : [];

        let interdevice_alerts = this.generateInterDeviceAlertSchema();

        let data = {
          unit_name: this.updateInfo.unit_name,
          parent_id: this.updateInfo.parent_id,
          gps_active: this.updateInfo.gps_active,
          gps_log_active: this.updateInfo.gps_log_active,
          link_validity: this.updateInfo.link_validity,
          interdevice_alerts,
          vibration_pushb_template: this.updateInfo.vibration_pushb_template,
          vibration_mandown_template: this.updateInfo.vibration_mandown_template,
          vibration_callb_template: this.updateInfo.vibration_callb_template,
          vibration_callb2_template: this.updateInfo.vibration_callb2_template,
          sms_alert: this.updateInfo.sms_alert,
          sms_alert_phone: smsPhone,
          sms_pushb_template: this.updateInfo.sms_pushb_template ? this.updateInfo.sms_pushb_template.trim() : "",
          sms_mandown_template: this.updateInfo.sms_mandown_template ? this.updateInfo.sms_mandown_template.trim() : "",
          sms_callb_template: this.updateInfo.sms_callb_template ? this.updateInfo.sms_callb_template.trim() : "",
          sms_callb2_template: this.updateInfo.sms_callb2_template ? this.updateInfo.sms_callb2_template.trim() : "",
          sms_five_push_template: this.updateInfo.sms_five_push_template ? this.updateInfo.sms_five_push_template.trim() : "",
          sms_timer_alarm_template: this.updateInfo.sms_timer_alarm_template ? this.updateInfo.sms_timer_alarm_template.trim() : "",
          display_alert: this.updateInfo.display_alert,
          display_alert_phone: displayPhone,
          display_pushb_template: this.updateInfo.display_pushb_template ? this.updateInfo.display_pushb_template.trim() : "",
          display_mandown_template: this.updateInfo.display_mandown_template ? this.updateInfo.display_mandown_template.trim() : "",
          display_callb_template: this.updateInfo.display_callb_template ? this.updateInfo.display_callb_template.trim() : "",
          display_callb2_template: this.updateInfo.display_callb2_template ? this.updateInfo.display_callb2_template.trim() : "",
          display_timer_template: this.updateInfo.display_timer_template ? this.updateInfo.display_timer_template.trim() : "",
          send_to_alarm_center: this.updateInfo.send_to_alarm_center,
          tunnel_settings: this.updateInfo.tunnel_settings,
          ava_flex : {
            tag_serial_number: this.updateInfo.ava_flex.tag_serial_number,
            webhook: this.updateInfo.ava_flex.webhook,
            camera_token: this.updateInfo.ava_flex.camera_token,
          }
        };
  
        // start move alarm
        // change fields when device device is moved between unit
        if (this.alarmInfo.parent_id != this.updateInfo.parent_id) {
          let oldUnit = _.find(this.all_units, { unit_id: this.alarmInfo.parent_id });
          let newUnit = _.find(this.all_units, { unit_id: this.updateInfo.parent_id });

          // when moved between units
          if (newUnit) {
            data.alarm_unit_name = newUnit.name;
            data.from_address = {
              city: `${newUnit.address} ${newUnit.zip_code} ${newUnit.city}`,
            };
          }

          // when moved between customer
          if (oldUnit && newUnit && oldUnit.parent_id != newUnit.parent_id) data.interdevice_alerts = [];
        }
        // end move alarm

        this.axios
          .put(`${process.env.VUE_APP_SERVER_URL}/alarms/${this.updateInfo._id}`, data)
          .then(() => {
            //eslint-disable-line
            this.$emit("updateAlarm");
            // update device tree
            if (this.alarmInfo.parent_id != this.updateInfo.parent_id) this.$emit("updateDeviceTree");
            this.$modal.hide("update-alarm");
            this.handleSuccess();
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    generateInterDeviceAlertSchema() {
      let events = [];

      for (let i = 0; i < this.interdeviceAlertSelectNodes.length; i++) {
        let node = this.interdeviceAlertSelectNodes[i];
        // build desired schema from selected objects
        if (node.level == "event") {
          let devices = [];

          // process all event children
          for (let j = 0; j < node.children.length; j++) {
            let unit = node.children[j];

            // process all unit children
            for (let k = 0; k < unit.children.length; k++) {
              let device = unit.children[k];
              devices.push(device.imei_number);
            }
          }

          // check if event object already exists
          let e = _.findIndex(events, { id: node.id });

          if (e > -1) {
            let event = events[e];
            event.devices = [...event.devices, ...devices];
          } else {
            events.push({
              id: node.id,
              devices,
            });
          }
        } else if (node.level == "unit") {
          let devices = [];

          // devices
          for (let j = 0; j < node.children.length; j++) {
            let device = node.children[j];
            devices.push(device.imei_number);
          }

          // check if event object already exists
          let e = _.findIndex(events, { id: node.event });

          if (e > -1) {
            let event = events[e];
            event.devices = [...event.devices, ...devices];
          } else {
            events.push({
              id: node.event,
              devices,
            });
          }
        } else if (node.level == "device") {
          let e = _.findIndex(events, { id: node.event });

          if (e > -1) {
            let event = events[e];
            event.devices.push(node.imei_number);
          } else {
            events.push({
              id: node.event,
              devices: [node.imei_number],
            });
          }
        }
      }

      return events;
    },

    populateInterDeviceAlertTree() {
      // populate tree
      // generate selected node array
      let selectedNodes = [];
      let interdeviceAlerts = [];

      // populate event based on device type
      for (let i = 0; i < this.interdeviceAlertsDefault.length; i++) {
        let event = this.interdeviceAlertsDefault[i];

        if (this.updateInfo && ((/(^SRT)|(^TWIG)/.test(this.updateInfo.type) && /(^4gbox)|(^ajax_critical)/.test(event.id)) ||
          (/(^TWIG)|(^SRT30)|(^SRT326$)|(^SRT278$)/.test(this.updateInfo.type) && event.id == "call_b2") ||
          (this.updateInfo.type == "4G Box" && event.id != "4gbox") || 
          (/(^AJAX)/.test(this.updateInfo.type) && event.id != "ajax_critical")
        )) continue;
        else interdeviceAlerts.push(event);
      }
      this.interdeviceAlerts = interdeviceAlerts;

      // populate event childs
      for (let i = 0; i < this.interdeviceAlerts.length; i++) {
        let event = this.interdeviceAlerts[i];

        if (i == 0) event.isDefaultExpanded = true;
        else event.isDefaultExpanded = false;

        // uniq id to each node
        let unitDevicesTree = _.cloneDeep(this.unitDevicesTree);
        let topUnits = [];
        let units = [];

        for (let j = 0; j < unitDevicesTree.length; j++) {
          let unit = unitDevicesTree[j];
          unit.event = event.id;
          // rename ids so they be unique across all events/units/devices
          unit.id = `${event.id}_${unit.id}`;

          if (event.id == "all" && this.alarmInfo && this.alarmInfo.parent_id == unit.unit_id) unit.isDefaultExpanded = true;
          else unit.isDefaultExpanded = false;

          let children = [];

          for (let k = 0; k < unit.children.length; k++) {
            let device = unit.children[k];
            device.event = event.id;
            device.id = `${event.id}_${unit.id}_${device.id}`;

            if (this.updateInfo && device._id != this.updateInfo._id && ( 
              (/^SRT/.test(this.updateInfo.type) && /^SRT((?!(334)|(330)|(430)).)*$/.test(device.type) ) || 
              (/^TWIG/.test(this.updateInfo.type) && /^TWIG((?!Beacon).)*$/.test(device.type) ) || 
              (/^TWIG/.test(this.updateInfo.type) && /^TWIG((?!Anchor Beacon).)*$/.test(device.type) ) || 
              (/^4G Box/.test(this.updateInfo.type) && /(^TWIG((?!Beacon).)*$)/.test(device.type)) || 
              (/^AJAX/.test(this.updateInfo.type) && /((^TWIG((?!Beacon).)*$))|(^SRT((?!(334)|(330)|(430)|(326)|(306)|(278)|(341)).))/.test(device.type)))) {
                // todo: 4gbox add srt40 series devices
                children.push(device);
                
                // populate selected nodes if device match
                let ei = this.updateInfo ? _.findIndex(this.updateInfo.interdevice_alerts, { id: event.id }) : -1; 
                if(ei > -1) {
                  let ev = this.updateInfo.interdevice_alerts[ei];
                  if(ev && ev.devices.indexOf(device.imei_number) > -1) selectedNodes.push(device);
                }
            }
          }

          unit.children = children;

          if (this.alarmInfo && this.alarmInfo.parent_id == unit.unit_id) {
            unit.isNew = true;
            if (unit.children.length == 0) unit.isDisabled = true;
            topUnits.push(unit);
          } else if (unit.children.length > 0) units.push(unit);
        }

        event.children = [...topUnits, ...units];
      }

      this.interdeviceAlertSelectNodes = selectedNodes;
    },

    getInternalDevicesAlertType() {
      if (/^SRT40/.test(this.updateInfo.type)) return "On-Screen Message";
      else if (/^SRT/.test(this.updateInfo.type)) return "9-vibb";
      else if (/^TWIG/.test(this.updateInfo.type)) return "On-Screen Message";
      else if (/^4G Box/.test(this.updateInfo.type)) return "On-Screen Message";
      else if (/^AJAX/.test(this.updateInfo.type)) return "On-Screen Message";
      else return "N/A";
    },

    addSMSInput() {
      this.updateInfo.sms_alert_phone.push({ msisdn: "" });
    },

    removeSMSInput(i) {
      this.updateInfo.sms_alert_phone.splice(i, 1);
    },

    addDisplayInput() {
      this.updateInfo.display_alert_phone.push({ msisdn: "" });
    },

    removeDisplayInput(i) {
      this.updateInfo.display_alert_phone.splice(i, 1);
    },

    filterPhoneNumber(phoneNumbers) {
      let recipients = [];

      for (let i = 0; i < phoneNumbers.length; i++) {
        let phone = phoneNumbers[i].msisdn.replace(/[^0-9]/g, "");
        recipients.push({
          msisdn: phone,
        });
      }

      recipients = _.uniqBy(recipients, "msisdn");

      return recipients;
    },

    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    // eslint-disable-next-line
    resetForm() {},

    hide(id) {
      this.$refs.formUpdateAlarm.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
    // start move alarm
    if (this.user.role == "admin" || this.user.role == "superuser") {
      this.getCustomers();
      this.getUnits();
    } else if (this.user.role == "reseller" && this.id != "-") {
      this.getResellerCustomers();
      this.getResellerUnits();
    }
    // end move alarm
  },
};
</script>
